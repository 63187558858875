<template>
  <div v-if='selectedContentSourceUrl' class='h-full'>
    <div style='position:relative; padding-bottom:56.25%; overflow:hidden;'>
      <iframe
        id='previewPlayer'
        name='previewPlayer'
        width='100%'
        height='100%' 
        :src='`${selectedContentSourceUrl}&auto=true&volume=0`'
        frameborder='0'
        allowfullscreen
        allow='autoplay;fullscreen;'
        style='position:absolute;'>
      </iframe>
    </div>
  </div>
</template>
<script>
import isEmpty  from 'lodash/isEmpty'

export default {
  name: 'LiveVideoPreviewContentWecandeo',
  props: ['showingContent'],
  computed: {
    hasShowingContent () {
      return !isEmpty(this.showingContent)
    },
    selectedContentSourceUrl () {
      if (this.hasShowingContent) {
        return this.showingContent.data.sourceUrl
      } else {
        return ''
      }
    }
  },
}
</script>
